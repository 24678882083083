<template>
  <div class="block_rules">
    <div>Gagner un cadeaux en donnant votre commentaire</div>
    <ol>
      <li>
        Les clients jouent à un jeu de roue de la fortune et reçoivent un cadeau offert par le
        magasin après avoir laissé un commentaire.
      </li>
      <li>Les clients doivent faire un achat le jour même pour participer au jeu.</li>
      <li>Un client ne peut participer qu'une seule fois à l'activité.</li>
    </ol>
  </div>
</template>

<script setup lang="ts"></script>

<style scoped lang="scss">
.block_rules {
  position: relative; // 确保伪元素定位正确
  background: radial-gradient(circle at center, #212f56 0%, #000000 100%);
  margin: 1rem;
  padding: 1rem;
  border-radius: 1rem;

  // 在.block_rules之前添加一个伪元素来创建模糊效果
  &::before {
    content: '';
    position: absolute;
    top: -10px; // 扩展伪元素的范围以增加模糊边缘
    right: -10px;
    bottom: -10px;
    left: -10px;
    border-radius: 1rem; // 保持和父元素相同的圆角
    background: inherit; // 继承父元素的背景
    z-index: -1; // 确保伪元素在内容下面
    filter: blur(15px); // 应用模糊效果
    overflow: hidden; // 防止模糊扩散到外部
  }

  // 调整内容层级，确保其位于模糊层之上
  > * {
    position: relative;
    z-index: 1;
  }
}
</style>
