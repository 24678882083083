<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <footer class="fixed bottom-0 left-0 m-2 mb-1 overflow-hidden w-full white-space-nowrap">
    <a :href="privacyUrl" target="_blank" rel="noopener">Privacy Policy</a>
    / Copyright © 2024 Powered by
    <a :href="followParisUrl" target="_blank"> Follow Paris </a>
  </footer>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue';

const hash: string | undefined = import.meta.env.VITE_GIT_HASH;
const baseUrl = 'https://github.com/jim60105/UnfairSpinWheel';
const version = ref('v.DEVELOP');
const url = ref(baseUrl);
const privacyUrl = 'https://www.followparis.com/privacy';
const followParisUrl = 'https://www.followparis.com';

onMounted(() => {
  if (hash) {
    version.value = 'v.' + hash.substring(0, 7).toUpperCase();
    url.value = `${baseUrl}/tree/${hash}`;
  }
});
</script>

<style scoped lang="scss">
footer {
  // font-family: 'Rock Salt';
  text-align: center;

  background-image: linear-gradient(to right, transparent 25%, black 100%);
  font-size: 0.85rem;
}
</style>
