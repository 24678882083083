<template>
  <Dropdown
    id="group-dropdown"
    :model-value="GroupLabel"
    :options="GroupLabels"
    class="mt-4 z-1"
    @update:model-value="itemService?.changeGroupLabel"
    :pt="{
      input: {
        class: 'text-xl sm:text-4xl md:text-6xl'
      },
      item: {
        class: 'text-xl sm:text-xl md:text-3xl'
      }
    }"
  />

  <div class="header_container">
    <div id="spinWheelTitle" class="mt-4 z-1">
      <span class="spinWheelTitle_container">
        {{ GroupLabel }}
        <a target="_blank" href="https://www.followparis.com/">
          <img style="width: 52px" src="https://www.followparis.com/images/icon_paris.png" alt="" />
        </a>
      </span>
    </div>

    <div id="prizeTodayTitle" class="mt-4 z-1">
      <div class="prizeTodayTitle_containre">
        <span
          class="prizeTodayTitle_container"
          v-if="Items && Items.length > 0 && PrizeToday?.currentIndex"
        >
          {{ Items[PrizeToday?.currentIndex]?.label }}
          <span>
            {{
              new Date(PrizeToday?.createAt).toLocaleDateString() +
              ' ' +
              new Date(PrizeToday?.createAt).toLocaleTimeString()
            }}
          </span>
        </span>
        <div class="button_win_prize" v-if="Items && Items.length > 0 && PrizeToday?.currentIndex">
          <a target="_blank" href="https://g.page/r/CYy7cO8uQpUbEBM/review">Gagner votre cadeau</a>
        </div>
      </div>
    </div>
  </div>

  <ShareLink class="w-full flex justify-content-center z-1 mt-3" />

  <div ref="container" class="flex spin-container">
    <picture>
      <source srcset="/img/image.avif" type="image/avif" />
      <source srcset="/img/image.webp" type="image/webp" />
      <img src="/img/image.png" class="image" alt="background image" />
    </picture>
    <div
      class="icon"
      @click="spin"
      @keyup.enter="spin"
      @keyup.space="spin"
      v-tooltip.bottom="{
        value: `↻ Spin!`,
        class: 'text-xl',
        escape: true
      }"
      tabindex="0"
    ></div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, inject, watch } from 'vue';
import random from 'random';
import { Wheel } from 'spin-wheel';
import { useDialog } from 'primevue/usedialog';
import { TickSound, LabelLength } from '@/services/SettingService';
import { GroupLabel, GroupLabels, ItemService, Items, PrizeToday } from '@/services/ItemService';
import CongratulationDialog from '@/components/CongratulationDialog.vue';
import type { IPrizeToday } from '@/interface/IPrizeToday';

const itemService = inject<ItemService>('ItemService');

const properties = {
  // debug: import.meta.env.DEV,
  isInteractive: false,
  radius: 0.48,
  rotationResistance: 0,
  itemLabelRadius: 0.92,
  itemLabelRadiusMax: 0.3,
  itemLabelRotation: 180,
  itemLabelAlign: 'left',
  itemLabelColors: ['#fff'],
  itemLabelBaselineOffset: -0.07,
  // Should also change app.scss
  itemLabelFont:
    '"Suez One", "Mochiy Pop P One", "Jua", "Unbounded", "Mitr", "Noto Sans TC", "Noto Sans SC", "Noto Sans Lao", "Noto Color Emoji"',
  itemLabelFontSizeMax: 55,
  itemBackgroundColors: [
    '#fdc963',
    '#00cca8',
    '#2b87e9',
    '#fd775b',
    '#ff4b78',
    '#c88857',
    '#a64a97',
    '#5b7c7d',
    '#715344',
    '#904e55',
    '#8b7856'
  ],
  rotationSpeedMax: 2000,
  lineWidth: 1,
  lineColor: '#fff',
  // image: './img/icon.png',
  // overlayImage: './img/image.png',
  items: []
};

const container = ref();

let spinCount = 0;
let wheel: Wheel | undefined = undefined;

const stopAndClearSound = () => {
  if (!wheel) return;

  wheel.onCurrentIndexChange = undefined;
  wheel.stop();
};

const playSound = () => {
  if (!TickSound.value) return;

  var src = TickSound.value.value.startsWith('data:')
    ? TickSound.value.value
    : `/sound/${TickSound.value.value}`;
  const audio = new Audio(src);
  audio.volume = 0.3;
  audio.play();
};

const spin = () => {
  if (!wheel) return;

  wheel.onCurrentIndexChange = () => {
    if (!wheel) return;

    playSound();

    // Change rotation resistance based on current speed.
    // Provide a more entertaining performance.
    switch (true) {
      case wheel.rotationSpeed < 400:
        wheel.rotationResistance = -100;
        break;
      case wheel.rotationSpeed < 100:
        wheel.rotationResistance = -30;
        break;
      case wheel.rotationSpeed < 30:
        wheel.rotationResistance = -10;
        break;
    }
  };

  wheel.rotationResistance = -400;
  wheel.spin(wheel.rotationSpeed + random.int(800, 1200));
};

const dialog = useDialog();
const openCongratulationDialog = ($event: {
  type: 'rest';
  currentIndex: number;
  currentIndexCreatedAt: string;
  rotation: number;
  buttonText: string;
}) => {
  // Check if we have already awarded the prize today

  dialog.open(CongratulationDialog, {
    props: {
      modal: true,
      showHeader: false,
      style: 'border: 0',
      contentStyle: 'border: 0; backgroundColor: transparent',
      dismissableMask: true
    },
    data: {
      item: Items.value![$event.currentIndex],
      itemCreatedAt: $event.currentIndexCreatedAt,
      buttonText: $event.buttonText
    }
  });
};

onMounted(async () => {
  watch(Items, (newValue) => (wheel!.items = newValue || []));
  watch(LabelLength, (newValue) => {
    wheel!.itemLabelRadiusMax = 1 - newValue;
  });

  wheel = new Wheel(container.value, {
    ...properties,
    items: Items.value,
    itemLabelRadiusMax: 1 - LabelLength.value
  });

  wheel.spin(10);

  // @JIEAN: Get today prize index from db_prizes on phase onMounted
  let prizeToday: IPrizeToday | null | undefined = await itemService?.getTodayPrize();
  console.log('prizeToday init', prizeToday);

  wheel.onRest = async ($event) => {
    prizeToday = await itemService?.getTodayPrize();

    stopAndClearSound;

    if (prizeToday === null || prizeToday === undefined) {
      // @JIEAN: Save today prize into db_prizes when reset the wheel
      itemService?.saveTodayPrizeCurrentIndex($event.currentIndex);

      openCongratulationDialog({
        ...$event,
        currentIndexCreatedAt: new Date().toLocaleString(),
        buttonText: 'Gagner votre cadeau en laissant un commentaire Google'
      });
    } else {
      openCongratulationDialog({
        type: 'rest',
        currentIndex: prizeToday.currentIndex,
        currentIndexCreatedAt: new Date(prizeToday.createAt).toLocaleString(),
        rotation: 0,
        buttonText: 'Oups, vous avez déjà jouer :/ Revenz jouer dans 3 jours '
      });
    }
  };

  wheel.onSpin = () => {
    gtag('event', 'spin');
    gtag('event', 'spin_count', {
      count: ++spinCount
    });
  };

  // Workaround for itemLabelRadiusMax not working on first load.
  setTimeout(() => {
    wheel!.itemLabelRadiusMax = 1 - LabelLength.value;
  }, 50);

  if (prizeToday === null || prizeToday === undefined) return;

  openCongratulationDialog({
    type: 'rest',
    currentIndex: prizeToday.currentIndex,
    currentIndexCreatedAt: new Date(prizeToday.createAt).toLocaleString(),
    rotation: 0,
    buttonText: "Félicitation ! Récupère votre cadeau à l'accueil en laissant un commentaire Google"
  });
});
</script>

<style lang="scss" scoped>
@import 'primeflex/core/_variables.scss';

.spin-container {
  aspect-ratio: 1/1;
  width: 200vw;
  height: 90vh;

  margin-top: -3.5rem;
  margin-bottom: -10vh;
  position: relative;

  @media (min-width: map-get($breakpoints, 'sm')) {
    height: 100vh;
  }

  @media (min-width: map-get($breakpoints, 'md')) {
    height: 110vh;
  }
}

.image {
  object-position: center;
  object-fit: contain;

  aspect-ratio: 1/1;
  width: 200vw;
  height: 90vh;

  position: absolute;
  top: calc(calc(50%) - calc(90vh / 2));
  left: calc(calc(50%) - calc(200vw / 2));

  @media (min-width: map-get($breakpoints, 'sm')) {
    height: 100vh;
    top: calc(calc(50%) - calc(100vh / 2));
  }

  @media (min-width: map-get($breakpoints, 'md')) {
    height: 110vh;
    top: calc(calc(50%) - calc(110vh / 2));
  }
}

.button-container {
  margin-top: -5.5rem;

  button {
    z-index: 2;
    position: relative;

    $background-color: #0c0f1d;
    background: $background-color;

    &:hover {
      filter: brightness(1.3);
    }
  }
}

.icon {
  $icon-size: 13vh;
  cursor: pointer;

  width: $icon-size;
  height: $icon-size;
  border-radius: 50%;

  background-image: url(/img/icon.png);
  background-image: -webkit-image-set(
    url(/img/icon.avif) type('image/avif'),
    url(/img/icon.webp) type('image/webp'),
    url(/img/icon.png) type('image/png')
  );
  background-image: image-set(
    url(/img/icon.avif) type('image/avif'),
    url(/img/icon.webp) type('image/webp'),
    url(/img/icon.png) type('image/png')
  );

  background-size: contain;

  position: absolute;
  top: calc(calc(50%) - calc($icon-size / 2));
  left: calc(calc(50%) - calc($icon-size / 2));

  &:hover {
    filter: brightness(1.1);
  }
}

// @JIEAN: Create to show Title instead of seldropDown
#group-dropdown {
  display: none;
}

.header_container {
  #spinWheelTitle {
    font-size: 3rem;
    color: rgba(255, 255, 255, 0.87);
    padding: 0.5rem 0.75rem;

    // width: 100%;
    text-align: center;
    background-color: rgba($color: #000000, $alpha: 0.6);
    border-radius: 0.5rem;

    .spinWheelTitle_container {
      padding: 1rem;
    }
  }

  #prizeTodayTitle {
    text-align: center;
    padding: 1rem 1.5rem;
    margin: 2.5rem 0;

    .prizeTodayTitle_containre {
      display: inline-block;
      color: rgba(255, 255, 255, 0.87);
      border-radius: 0.5rem;
      font-size: 2rem;
      padding: 0.5rem 0.75rem;

      // width: 100%;
      text-align: center;
      background-color: rgba($color: #000000, $alpha: 0.6);

      .prizeTodayTitle_container {
        padding: 0.5rem;
      }

      .button_win_prize {
        margin-top: 0.5rem;
        text-align: center;
        a {
          padding: 10px 20px; // Adjust padding to your liking
          background-color: #1b7ae2; // A nice shade of blue, change as needed
          color: #ffffff; // White text color
          font-size: 16px; // Adjust font-size as needed
          border: none; // No border for a flat design
          border-radius: 5px; // Rounded corners
          cursor: pointer; // Cursor indicates it's clickable
          transition: background-color 0.3s ease; // Smooth background transition on hover

          &:hover {
            background-color: darken(
              $color: #3498db,
              $amount: 10%
            ); // Darken the button color on hover
          }

          &:focus,
          &:active {
            outline: none; // Remove the default focus outline
            box-shadow: 0 0 0 2px rgba(52, 152, 219, 0.5); // Add a subtle focus box shadow
          }

          &:disabled {
            background-color: lighten(
              $color: #3498db,
              $amount: 10%
            ); // Lighten the button when disabled
            cursor: not-allowed; // Change cursor for disabled state
          }
        }
      }
    }
  }
}
</style>
