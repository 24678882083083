import { ref } from 'vue';
import PouchDB from 'pouchdb-browser';
import type { IItem } from '@/interface/IItem';
import type { IPrizeToday } from '@/interface/IPrizeToday';
import { templateItems } from '@/assets/TemplateData';
import { Fairmode } from './SettingService';

export const GroupLabel = ref<string>();
export const GroupLabels = ref<string[]>([]);
export const Items = ref<PouchDB.Core.ExistingDocument<IItem>[]>();
export const PrizeToday = ref<IPrizeToday | null>();

const isDeleteAllDocsInPunchDB = false; // @JIEAN: set to true to delete all docs in PouchDB

export class ItemService {
  private version = 'v0.0.5'; // @JEIAN: version tools
  private defaultGroupLabel = 'Star Food';

  private db: PouchDB.Database<IItem> = new PouchDB('item-' + this.version);
  private db_prizes: PouchDB.Database<IPrizeToday> = new PouchDB('prize-' + this.version); // @Jiean: Save info of today prize

  async init() {
    const dbInfo = await this.db.info();
    if (dbInfo.doc_count !== 0) {
      this.db.compact();
    }

    if (isDeleteAllDocsInPunchDB) {
      const allDocs = await this.db.allDocs<IItem>({ include_docs: true });
      // console.log('allDocs', allDocs);
      allDocs.rows.forEach((row) => this.db.remove(row.id, row.value.rev));

      const allDocs_prizes = await this.db_prizes.allDocs<IItem>({ include_docs: true });
      // console.log('allDocs_prizes', allDocs_prizes);
      allDocs_prizes.rows.forEach((row) => this.db_prizes.remove(row.id, row.value.rev));
    }

    await this.resetGroupLabel(this.defaultGroupLabel);
    Items.value = await this.getItems(this.defaultGroupLabel);
    GroupLabel.value = 'Qui sera le prochain gagnant ?';
  }

  public getItemCount = async () => (await this.db.info()).doc_count;

  public getGroupLabels = async (): Promise<string[]> => [
    ...new Set(
      (await this.db.allDocs<IItem>({ include_docs: true })).rows
        .map((row) => row.doc!.group)
        .filter((group) => !!group)
        .sort()
    )
  ];

  public getItems = async (
    groupLabel_name?: string
  ): Promise<PouchDB.Core.ExistingDocument<IItem>[]> => {
    if (groupLabel_name) {
      return this.getItemByGroupLabel(groupLabel_name);
    } else {
      return this.getItemByGroupLabel(
        // @JIEAN: Add this.getGroupLabelDefaultItem()).group for getItems function
        // (await this.getGroupLabelDefaultItem()).group || (await this.getFirstItem()).group
        (await this.getFirstItem()).group
      );
    }
  };

  public getItemByGroupLabel = async (
    groupLabel: string
  ): Promise<PouchDB.Core.ExistingDocument<IItem>[]> =>
    (
      await this.db.find({
        selector: {
          group: groupLabel
        }
      })
    ).docs
      .sort((a, b) => a.order - b.order)
      .map((p) => {
        const copy = Object.assign({}, p);
        if (Fairmode.value) {
          copy.weight = 1;
        }
        return copy;
      });

  private syncGroups = async () => (GroupLabels.value = await this.getGroupLabels());
  public syncItems = async () => (Items.value = await this.getItems());
  public syncPrizeToday = async () => {
    const todayPrize: IPrizeToday | null = await this.getTodayPrize();

    if (todayPrize) {
      PrizeToday.value = todayPrize;
    } else {
      PrizeToday.value = null;
    }
  };

  // private async getGroupLabelDefaultItem(): Promise<IItem> {
  //   const groupLabelDefaultItem = {
  //     group: this.defaultGroupLabel,
  //     // label, weight and order are usesless here
  //     label: '',
  //     weight: 0,
  //     order: 0
  //   };

  //   const item = (await this.db.allDocs<IItem>({ include_docs: true })).rows
  //     .sort((a, b) => a.doc!.order - b.doc!.order)
  //     .shift();
  //   if (!item) {
  //     await this.insertTemplateItems();
  //     await this.syncItems();
  //     return this.getGroupLabelDefaultItem()!;
  //   } else {
  //     this._firstItem = item.doc;
  //     return groupLabelDefaultItem!;
  //   }
  // }

  public addItem = async (item?: IItem): Promise<PouchDB.Core.Response> => {
    if (!item)
      item = {
        group: GroupLabel.value ?? 'New Group',
        label: 'New Item',
        weight: 1,
        order: (await this.getItemCount()) ?? 0
      };

    const doc = item as PouchDB.Core.ExistingDocument<IItem>;
    doc._id = '';
    doc._rev = '';
    const result = await this.db.post(doc);
    await this.syncGroups();
    await this.syncItems();
    return result;
  };

  public addItems = async (
    items: IItem[]
  ): Promise<(PouchDB.Core.Error | PouchDB.Core.Response)[]> => {
    let count = await this.getItemCount();
    const docs = items.map((item) => ({
      group: item.group,
      label: item.label,
      weight: item.weight,
      order: count++
    }));
    const result = await this.db.bulkDocs(docs);

    await this.syncGroups();
    await this.syncItems();
    return result;
  };

  private insertTemplateItems = (): Promise<(PouchDB.Core.Error | PouchDB.Core.Response)[]> =>
    this.db.bulkDocs(templateItems);

  private _firstItem: PouchDB.Core.ExistingDocument<IItem> | undefined;

  public async getFirstItem(): Promise<PouchDB.Core.ExistingDocument<IItem>> {
    if (this._firstItem) return this._firstItem;

    const item = (await this.db.allDocs<IItem>({ include_docs: true })).rows
      .sort((a, b) => a.doc!.order - b.doc!.order)
      .shift();
    if (!item) {
      await this.insertTemplateItems();
      await this.syncItems();
      return this.getFirstItem()!;
    } else {
      this._firstItem = item.doc;
      return this._firstItem!;
    }
  }

  public changeGroupLabel = async (newGroupLabel: string) => {
    if (newGroupLabel !== GroupLabel.value) {
      GroupLabel.value = newGroupLabel;
    }
    await this.syncItems();
  };

  // @JIEAN: Add getTodayPrizeCurrentIndex function
  public getTodayPrize = async (): Promise<IPrizeToday | null> => {
    function isToday(date: Date) {
      const today = new Date();

      // 👇️ Today's date
      // console.log(today);

      if (today.toDateString() === date.toDateString()) {
        return true;
      }

      return false;
    }

    const docs = await this.db_prizes.allDocs<IPrizeToday>({ include_docs: true });
    console.log('docs', docs);
    const results = docs.rows
      .filter((row) => isToday(new Date(row.doc!.createAt)))
      .sort((a, b) => new Date(b.doc!.createAt).getTime() - new Date(a.doc!.createAt).getTime());
    console.log('results', results);
    if (results.length === 0) {
      return null;
    }
    return results[results.length - 1].doc!;
  };

  // @JIEAN: Add saveTodayPrizeCurrentIndex function
  public saveTodayPrizeCurrentIndex = async (currentIndex: number) => {
    const doc = {
      _id: new Date().toISOString(),
      currentIndex,
      createAt: new Date()
    };
    // @JIEAN: Update PrizeToday value when not existing PrizeToday value
    if (!PrizeToday.value) {
      PrizeToday.value = doc;
    }
    await this.db_prizes.put(doc);
  };

  public async resetGroupLabel(groupLabel_name?: string) {
    if (groupLabel_name) {
      GroupLabel.value = groupLabel_name;
    } else {
      const firstItem = await this.getFirstItem();
      GroupLabel.value = firstItem.group;
    }

    await this.syncGroups();
    await this.syncItems();
    await this.syncPrizeToday();
  }

  public async renameGroup(oldGroupLabel: string, newGroupLabel: string) {
    if (newGroupLabel === oldGroupLabel) return;

    const items: PouchDB.Core.ExistingDocument<IItem & PouchDB.Core.ChangesMeta>[] =
      await this.getItemByGroupLabel(oldGroupLabel);
    items.forEach((item) => (item.group = newGroupLabel));
    await this.db.bulkDocs(items);

    await this.syncGroups();
    await this.changeGroupLabel(newGroupLabel);
  }

  public async updateItem(
    item: PouchDB.Core.ExistingDocument<IItem>
  ): Promise<PouchDB.Core.Response> {
    const doc = await this.db.get(item._id);
    if (doc.label === item.label && doc.weight === item.weight)
      return Promise.resolve({} as PouchDB.Core.Response);

    doc.label = item.label;
    if (!Fairmode.value) doc.weight = item.weight;
    const result = await this.db.put(item);
    await this.syncItems();
    return result;
  }

  public removeItem = async (item: PouchDB.Core.ExistingDocument<IItem>) => {
    const _item: PouchDB.Core.ExistingDocument<IItem & PouchDB.Core.ChangesMeta> = item;
    _item._deleted = true;
    const result = await this.db.put(_item);
    await this.syncItems();
    return result;
  };

  public removeGroup = async (groupLabel: string) => {
    if (groupLabel === this._firstItem?.group) this._firstItem = undefined;

    await this.cleanUpGroup(groupLabel);
    await this.resetGroupLabel();
  };

  public cleanUpGroup = async (groupLabel: string) => {
    const items: PouchDB.Core.ExistingDocument<IItem & PouchDB.Core.ChangesMeta>[] =
      await this.getItemByGroupLabel(groupLabel);
    items.forEach((item) => (item._deleted = true));
    await this.db.bulkDocs(items);
    await this.syncItems();
  };
}
